import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  state: {
    errors: [],
    user: null,
  },
  mutations: {
    setErrors(state, errors) {
      state.errors = errors;
    },
    getUserData(state, data) {
      state.getuser = data;
    },
    setUserData(state, data) {
      state.user = data;
    }
  },
  actions: {
    StorelogData({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "LogUserApi", data);
    },
    CountryDropDynamic({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
      .post(process.env.VUE_APP_API_URL + "DropDownCountry", data);
    },
    DomgetUserplanlistperticular({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "DomesticUserplanrecord", data);
    },
    DomesticFetchModelData({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "DomesticFetchModelData", data);
    },
    SMSSendUser({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "usersms", data);
    },
     FetchModelData({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "FetchModelDataUser", data);
    },
    removeFromCartList({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "removeFromCartListApi", data);
    },
    CartCountRequest({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "FetchCartCount", data);
    },
    GetPlanUserHistory({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "FetchHistoryUser", data);
    },
    UpdateForgotPassword({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "updateforgotPasswordApi", data);
    },
    ForgotPassword({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "forgotPasswordApi", data);
    },
    sendInquiryE({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "sendInquiryEmail", data);
    },
    detailUpdate({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "UserCompanyDetail", data);
    },
    PaymentResponceFailed({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "PaymentResponceFailedApi", data);
    },
    PayuPaymentDetail({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "PaymentSendUser", data);
    },
    StateDroptDown({ commit }) {
      commit("setErrors", {}, { root: true });
      return axios
        .get(process.env.VUE_APP_API_URL + "DropDownState");
    },
    CountryWiseData({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "CountryWiseDataapi");
    },
    GiveaccessDetail({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "GiveaccessDetailaPI", data);
    },
    addToCart12({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "store-in-cart", data);
    },
    getUserData({ commit }) {
      commit("setErrors", {}, { root: true });
      return axios
        .get(process.env.VUE_APP_API_URL + "user-detail")
    },
    getUserplanlist({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "plan_list_detail", data)
    },
    getUserplanlistCheck({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "plan_list_detailCheck", data)
    },
    getUserUpdateList({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "UserEditDetaildashboard", data);
    },
    UpdateUserAdminDetail({ commit }, request) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "UpdatUserAdmin", request);
    },
    getUserplanlistperticular({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "Userplanrecord", data);
    },
    sendLoginRequest123({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "login", data)
        .then(response => {
          commit("setUserData", response.data);
          localStorage.setItem("token", response.data.data.token);
          localStorage.setItem("user", response.data.data.id);
        });
    },
    sendRegisterRequest12({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "register", data)
    },
    sendLogoutRequest({ commit }) {
      axios.get(process.env.VUE_APP_API_URL + "logout").then(() => {
        commit("setUserData", null);
        localStorage.removeItem("token");
      });
    },
    sendVerifyResendRequest() {
      return axios.get(process.env.VUE_APP_API_URL + "email/resend");
    },
    sendVerifyRequest({ dispatch }, hash) {
      return axios
        .get(process.env.VUE_APP_API_URL + "email/verify/" + hash)
        .then(() => {
          dispatch("getUserData");
        });
    }
  },
  getters: {
    errors: state => { return state.errors },
    user: state => { return state.user }
  },

});
